<template>
  <div class="vip">
    <div class="vip-header"></div>
    <div
      class="vip-body"
      :style="{
        width: '100%',
        height: height + 'px',
        background: '#F3F3F3',
        padding: '34px 27px 0px 27px',
      }"
    >
      <div class="vip-statistics">
        <span
          >会员总数：<span class="red-num">{{ topData.cust_num_total}}</span
          >个；收入合计： <span class="red-num">{{ topData.amt_add_sum }}</span
          >万元；<span
            >支出合计：<span class="red-num">{{ topData.amt_sub_sum }}</span
            >万元；</span
          ><span
            >余额合计：<span class="red-num">{{ topData.amt_end_sum }}</span
            >万元；<span
              >客单价：<span class="red-num">{{ topData.cust_unit_price }}</span
              >万元</span
            ></span
          ></span
        >
      </div>


      <div
        :style="{
          width: '97%',
          height: heightSmall + 'px',
          backgroundColor: '#fff',
          marginTop: '29px',
        }"
      >
        <div class="vip-condition">
          <div class="vip-all">
            <span @click="lookAll" style="display: flex;align-items:center;"><img src="../assets/back.png" alt="" style="width: 30px;height: 30px;">看全部</span>
            <span style="display: inline-block; width: 106px"></span>
            <el-button class="vip-buy" @click="dianji_xinzeng">充值</el-button>
            <span style="display: inline-block; width: 60px"></span>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              format="yyyy-MM-dd"
              @change="query_mc_trans_list_detail()"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <h1>会员充值明细账页</h1>
          <div class="vip-right">
            <span style="display: inline-block; width: 30px"></span>
            <el-button class="vip-exprot" @click="handeDownload()">
              导出</el-button
            >
            <span style="display: inline-block; width: 50px"></span>
            <!-- <el-input
              class="inputOp"
              placeholder="搜索客户名字或手机号"
              size="smallr"
              v-model="content"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input> -->
            <span style="display: inline-block; width: 20px"></span>
            <span class="vip-yuan">单位：元</span>
          </div>
        </div>
        <div class="vip-table">
          <el-table
            
            height="660"
            stripe
            :data="tableData"
            @selection-change="handleSelectionChange"
            @row-click="handleNeedClick"
            :header-cell-style="{ height: '80px', background: '#F9F9F9;' }"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column
              label="序号"
              align="center"
              type="index"
            ></el-table-column>
               <el-table-column
              label="日期"
              align="center"
              prop="date_trans"
            ></el-table-column>
            <el-table-column
              label="客户"
              align="center"
              prop="cust_name"
            ></el-table-column>
            <el-table-column
              label="手机号"
              align="center"
              prop="mobile"
            ></el-table-column>
            <el-table-column
              label="业务人员"
              align="center"
              prop="staff_name"
            ></el-table-column>
            <el-table-column
              label="归档号"
              align="center"
              prop="file_no"
            ></el-table-column>
            <el-table-column
              label="期初余额"
              align="center"
              prop="amt_bgn"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.amt_bgn | handleTH }}
              </template>
            </el-table-column>
            <el-table-column
              label="收入"
              align="center"
              prop="amt_add"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.amt_add | handleTH }}
              </template>
            </el-table-column>
            <el-table-column
              label="支出"
              align="center"
              prop="amt_sub"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.amt_sub | handleTH }}
              </template>
            </el-table-column>
            <el-table-column
              label="余额"
              align="center"
              sortable
              prop="amt_end"
            >
              <template slot-scope="scope">
                {{ scope.row.amt_end | handleTH }}
              </template>
            </el-table-column>
          </el-table>

          <div style="text-align: right; padding-top: 20px">
        <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 30, 40, 50, 75, 100]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
        </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" size="25%">
      <h2>{{ custName }}</h2>
      <div>
        <div class="vip-watch">
          <div>
            <div>成交时间</div>
            <div>---</div>
          </div>
          <div>
            <div>成交金额(元)</div>
            <div>0.00</div>
          </div>
          <div>
            <div>退回次数</div>
            <div>0</div>
          </div>
          <div>
            <div>状态</div>
            <div>指派</div>
          </div>
          <div>
            <img
              src="../assets/me/eye.png"
              @click="dialogVisible = true"
              style="width: 35px; height: 30px; border: none"
            />
          </div>
        </div>
        <div style="height: 30px"></div>
        <el-collapse
          v-model="activeNames"
          @change="handleChange"
          style="padding-left: 20px; padding-right: 20px"
        >
          <el-collapse-item title="详细信息" name="1">
            <div>
              与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
            </div>
            <div>
              在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
            </div>
          </el-collapse-item>
          <div style="height: 30px"></div>
          <el-collapse-item title="客户操作日志" name="2">
            <div>
              与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
            </div>
            <div>
              在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
            </div>
          </el-collapse-item>
          <div style="height: 30px"></div>
          <el-collapse-item title="附件下载" name="3">
            <div>
              与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
            </div>
            <div>
              在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
    <!-- <el-dialog
      title="客户相关的归档号"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="dialog-title">
        <div v-if="group">
          <el-radio-group
            v-model="radio"
            style="height: 380px; overflow-y: auto"
            :inline="true"
          >
            <el-radio size="small">--- 20200201-0049-2236</el-radio>
            <el-radio size="small">--- 20201207-0057-2135</el-radio>
          </el-radio-group>
        </div>
        <div v-if="!group"></div>
        <div class="btn-bottm" v-if="group">
          <button @click="group = false">确定</button>
        </div>
        <div class="btn-bottm" v-if="!group">
          <button @click="group = true">返回</button>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  query_mc_trans_list_detail,
  query_mc_trans_gather_data,
} from "@api/vip.js";
import { query_flow_info_list } from '@/api/api'
import {
   query_flow_info_all,
   query_form_list_file_nos
} from "@/api/api";
import { query_customer_else_info, query_customer_detail } from '@/api/api'
export default {
  filters: {
    handleTH(val) {
      return val.replace(/\B(?=(\d{3})+$)/g,',')
    }
  },
  name: "vip",
  data() {
    return {
      height: null,
      heightSmall: null,
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/'
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      // url: "http://192.168.3.5:8080/hwb_client/V1.0.0/",
      topData: {
        cust_num_total: "0",
        amt_add_sum: "0",
        amt_sub_sum: "0",
        amt_end_sum: "0",
      },

      activeNames:null,
      dateRange: null,
      reachContent: null,
      content: null,
      custName: null,
      cust_id: this.$route.params.id,
      cust_name: this.$route.params.name,
      TodayTime: null,
      direction: "rtl",
      multipleSelection: [],
      endRow: null,
      drawer: false,
      tableData: [],
      xiangxi_xinxi: [],
      total:100,//共计100页
       currentPage4: 1,//当前页数
       pagesize:10,//每页多少条


    };
  },
  computed: {
    ...mapGetters({
      infoData: "infoData",
    }),
  },

  created() {
    this.height = window.innerHeight - 37;
    this.heightSmall = this.height - 110;
    if(!this.cust_id) {
     this.cust_id = sessionStorage.getItem("kuhe_id")
      if( !cust_id){
        this.$message.warning('请选择先到会员总账查看')
      }else{
        this.cust_id = JSON.parse(cust_id)
        this.query_mc_trans_list_detail();
      }
    }else{
       this.query_mc_trans_list_detail();
    }
    
    },
  methods: {
    handleChange(){},
    handeDownload() {
      if(!this.dateRange) {
        const url = this.url +
          "export_detail_mc_acct?ent_id=" +
          this.$ent_id() +
          "&user_id=" +
          this.$jichuxinxi().user_id +
          "&search_str=" +
          this.cust_name
        const data = [url];
        console.log(data)
        for (let i = 0; i < data.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = data[i];
          document.body.appendChild(iframe);
          setTimeout((res) => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      } else {
        let cust_id = sessionStorage.getItem("kuhe_id")
        const url = this.url +
          "export_detail_mc_acct?ent_id=" +
          this.$ent_id() +
          "&user_id=" +
          this.$jichuxinxi().user_id +
          "&search_str=" +
          this.cust_name +
          "&date_start=" +
          this.dateRange[0] +
          "&date_end=" + this.dateRange[1]
        const data = [url];
        console.log(data)
        for (let i = 0; i < data.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = data[i];
          document.body.appendChild(iframe);
          setTimeout((res) => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      }
    },
    handleNeedClick (val) {
      query_customer_detail({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          file_no: '',
          dept_id: val.dept_id
        }
      }).then(rds => {
        console.log(rds)
        // if (rds.data.code == 200) {
        //   const xiangqing = JSON.parse(rds.data.body.data)
        //   console.log(xiangqing)

        //   this.xiangxi_xinxi = []
        //   this.xiangxi_xinxi.push(xiangqing)
        //   this.xiangxi_xinxi.forEach(item => {
        //     this.$set(item, 'zidingyi', item.extra != undefined ? JSON.parse(item.extra) : [])
        //     this.$set(item, 'dept_name', val.dept_name != undefined ? val.dept_name : '')
        //   })
        //   // 附件下载接口
        //   customer_annex_download({
        //     data: {
        //       ent_id: this.$ent_id(),
        //       cust_id: val.cust_id,
        //       // dept_id:val.dept_id,
        //       file_no: xiangqing.file_no,
        //       staff_id: val.staff_id != undefined ? val.staff_id : null
        //     }
        //   }).then(fujian => {
        //     console.log(fujian)
        //     if (fujian.data.code == 200) {
        //       const date = JSON.parse(fujian.data.body.data)
        //       console.log(date)
        //       date.forEach(item => {
        //         if (item.con_annex != undefined) {
        //           item.con_annex.forEach(ite => {
        //             this.$set(ite, 'zhi', false)
        //           })
        //         }
        //         if (item.inv_annex != undefined) {
        //           item.inv_annex.forEach(ite => {
        //             this.$set(ite, 'zhi', false)
        //           })
        //         }
        //         if (item.rec_annex != undefined) {
        //           item.rec_annex.forEach(ite => {
        //             this.$set(ite, 'zhi', false)
        //           })
        //         }
        //         if (item.deliver_annex != undefined) {
        //           item.deliver_annex.forEach(ite => {
        //             this.$set(ite, 'zhi', false)
        //           })
        //         }
        //       })
        //       for (let i = 0; i < date.length; i++) {
        //         if (date[i].file_no == undefined) {
        //           date.splice(i, 1)
        //           i = i - 1
        //         }
        //       }
        //       setTimeout(() => {
        //         this.fujian_list = date
        //       }, 0)
        //     } else if (fujian.data.code == 500) {}
        //   })
        // } else if (rds.data.code == 500) {}
      })
      // query_customer_mng_log_list({
      //   data: {
      //     ent_id: this.$ent_id(),
      //     cust_id: val.cust_id
      //   }
      // }).then(res => {
      //   console.log(res)
      //   if (res.data.code == 200) {
      //     const date = JSON.parse(res.data.body.data)
      //     console.log(date)
      //     this.zhanshi_lishi = date != undefined && date != null && date.length != 0 ? date : []

      //     // let list=[]
      //     // date.forEach(ite=>{
      //     //     // if(ite.type!=4&&ite.type!=5){
      //     //         list.push(ite)
      //     //     // }
      //     // })
      //     // query_cust_allot_log_list({data:{
      //     //     ent_id:this.$ent_id(),
      //     //     // allot_user_id:this.$jichuxinxi().user_id,
      //     //     cust_id:val.cust_id
      //     // }}).then(ras=>{
      //     //     console.log(ras)
      //     //     if(ras.data.code==200){
      //     //         let data=JSON.parse(ras.data.body.data)
      //     //         console.log(data)
      //     //         data.forEach(item=>{
      //     //             if(item.accept_status==0){
      //     //                 this.$set(item,'operate_time',item.allot_time)
      //     //                 this.$set(item,'type',7)
      //     //                 this.$set(item,'operate_user_name',item.allot_user_name)
      //     //                 list.push(item)
      //     //             }else if(item.accept_status==1){
      //     //                 this.$set(item,'operate_time',item.allot_time)
      //     //                 this.$set(item,'type',4)
      //     //                 this.$set(item,'operate_user_name',item.allot_user_name)
      //     //                 list.push(item)
      //     //             }else if(item.accept_status==2){
      //     //                 this.$set(item,'operate_time',item.allot_time)
      //     //                 this.$set(item,'type',5)
      //     //                 this.$set(item,'operate_user_name',item.allot_user_name)
      //     //                 list.push(item)
      //     //             }
      //     //         })
      //     //         list=this.$func.ReverseRankingDate(list,'operate_time')
      //     //         console.log(list)
      //     //         this.zhanshi_lishi=list
      //     //     }else if(res.data.code==500){}
      //     // })
      //   } else if (res.data.code == 500) {}
      // })
      // query_customer_else_info({
      //   data: {
      //     ent_id: this.$ent_id(),
      //     cust_id: val.cust_id,
      //     type: '2',
      //     dept_id: val.dept_id
      //   }
      // }).then(res => {
      //   console.log(res)
      //   if (res.data.code == 200) {
      //     const date = JSON.parse(res.data.body.data)
      //     console.log(date)
      //     console.log(that.lv1)
      //     console.log(that.lv2)
      //     if (Number(date.stay_long) >= Number(that.lv1) && Number(date.stay_long) <= Number(that.lv2)) {
      //       that.right_zhanshi.color = '0'
      //       that.right_zhanshi.tingliu_shichang = Number(that.lv1) + '-' + Number(that.lv2) + '天'
      //     } else if (Number(date.stay_long) > Number(that.yellow1) && Number(date.stay_long) <= Number(that.yellow2)) {
      //       that.right_zhanshi.color = '1'
      //       that.right_zhanshi.tingliu_shichang = Number(that.yellow1) + '-' + Number(that.yellow2) + '天'
      //     } else if (Number(date.stay_long) > Number(that.red)) {
      //       that.right_zhanshi.color = '2'
      //       that.right_zhanshi.tingliu_shichang = Number(that.red) + '天以上'
      //     }
      //     that.right_zhanshi.chengjiao_sj = date.date_trans.length != 0 ? date.date_trans : '—'
      //     that.right_zhanshi.chengjiao_jine = Number(date.amt_trans).toFixed(2)
      //     that.right_zhanshi.tuihui_cishu = date.back_num
      //   } else if (res.data.code == 500) {}
      // })
      // this.youce_xiangqing = true
      // } else {
      //   this.$message.Info('当前客户没有归档号无法查看详情信息')
      // }
    },
    async changDate() {},
    async query_mc_trans_list_detail() {
      try {
        this.loading = true
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let formatnowdate = y + "-" +  this.$func.pa(m) + "-" + this.$func.pa(d);
        date.setMonth(date.getMonth() - 1);
        var ago = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000); //30天前
        let day = ("0" + (ago.getDate() - 1)).slice(-2);
        let month = ("0" + (ago.getMonth() + 2)).slice(-2);
         let oneMonAgo = ago.getFullYear() + "-" + this.$func.pa(month) + "-" + this.$func.pa(day);
        if (this.dateRange) {
          oneMonAgo = this.dateRange[0];
          formatnowdate = this.dateRange[1];
        }
        let data = {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          date_start: oneMonAgo,
          date_end: formatnowdate,
          search_str: "",
          page_size: JSON.stringify(this.pagesize), //每页条数
          page_index: JSON.stringify(this.currentPage4),  //当前页数
          cust_id: this.cust_id,
        };

        //console.log('这是给后台的数据测试当前页数s')
        // console.log(data,'这里是我要传的数据')
        data = await query_mc_trans_list_detail({ data });

        if (data.data.code == 200) {
          let res = JSON.parse(data.data.body.data);
          this.endRow = res.endRow;
          this.tableData = res.list;
          console.log(this.tableData, 11111111)
          this.query_mc_trans_gather_data(oneMonAgo,formatnowdate)
          //console.log(this.tableData)
          this.tableData.forEach(item => {
            item.amt_bgn = item.amt_bgn.toFixed(2)
            item.amt_add = item.amt_add.toFixed(2)
            item.amt_sub = item.amt_sub.toFixed(2)
            item.amt_end = item.amt_end.toFixed(2)
            item.file_no = '-'
          })
          
          this.total = res.total //共36条
         //  this.currentPage4  =res.pageNum,
          //  this.pagesize = res.pageSize,
          //  this. total = res.pages
          // this.cur_page = res.pages;
          // this.pageSize = res.pageSize;
         //console.log(res, "这是分页");
        } else {
          this.$message.err("提交失败");
        }
      } catch {}
    },
    handleSelectionChange(){},
    async query_mc_trans_gather_data(date_start,date_end) {

        let data = {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          date_start,
          date_end,
        }

        const res = await query_mc_trans_gather_data({ data });
        console.log(res,'2323423432432这里获取')
        if (res.data.code === "200") {
            this.topData = JSON.parse(res.data.body.data);
            console.log(this.topData)
          this.topData.amt_add_sum = (Number(JSON.parse(res.data.body.data).amt_add_sum) / 10000).toFixed(2)
          this.topData.amt_sub_sum = (Number(JSON.parse(res.data.body.data).amt_sub_sum) / 10000).toFixed(2)
          this.topData.amt_end_sum = (Number(JSON.parse(res.data.body.data).amt_end_sum) / 10000).toFixed(2)
          this.topData.cust_unit_price = (Number(JSON.parse(res.data.body.data).cust_unit_price) / 10000).toFixed(2)
          //console.log(this.topData, 11111)
        } else {
          this.$message({
            message: "获取信息失败",
            type: "warning",
          });

      }
    },
    dianji_xinzeng () {

        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: 'AY3cli7BAb'
          }
        }).then(res => {
          const date = res.data.body.form_str
            console.log(date)
            const data = []
            for (let a = 0; a < date.length; a++) {
              if (date[a].defaultImportant != undefined) {
                date[a].defaultImportant = date[a].defaultImportant != 'false'
              }
              if (date[a].defaultPrint != undefined) {
                date[a].defaultPrint = date[a].defaultPrint != 'false'
              }
              if (date[a].defaultOptions != undefined) {
                date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
              }
              if (date[a].gongshi != undefined) {
                date[a].gongshi = JSON.parse(date[a].gongshi)
              }
              if (date[a].supportSetting != undefined) {
                date[a].supportSetting = JSON.parse(date[a].supportSetting)
              }
              if (date[a].components != undefined) {
                date[a].components = JSON.parse(date[a].components)
                for (let b = 0; b < date[a].components.length; b++) {
                  if (date[a].components[b].defaultImportant != undefined) {
                    if (typeof (date[a].components[b].defaultImportant) === 'string') {
                      date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                    }
                  }
                  if (date[a].components[b].defaultPrint != undefined) {
                    if (typeof (date[a].components[b].defaultPrint) === 'string') {
                      date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                    }
                  }
                  // typeof(str)=='string'
                  if (date[a].components[b].defaultOptions != undefined) {
                    if (typeof (date[a].components[b].defaultOptions) === 'string') {
                      date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                    }
                  }
                  if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                    if (typeof (date[a].components[b].gongshi) === 'string') {
                      console.log(date[a].components[b].gongshi)
                      date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                    }
                  }
                  if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                    if (typeof (date[a].components[b].supportSetting) === 'string') {
                      console.log(date[a].components[b].supportSetting)
                      date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                    }
                  }
                }
              }
              if (date[a].componentName != 'contacts' && date[a].defaultLable != '负责人') {
                data.push(date[a])
              }
            }
            const obj = {
              flow_id: 'AY3cli7BAb',
	            flow_name: '会员充值单'
            }
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '公海池', '暂时')
            this.$router.push('/usetable')
        })


        // this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
        // this.$router.push('/usetable')
    },
    handleSizeChange(val) {
       this.pagesize = val
        this.query_mc_trans_list_detail()
          //
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.query_mc_trans_list_detail()
    },
    lookAll() {
      this.$router.go(-1);
    },
    //  handleCurrentChange(page) {
    //   // 当前页
    //   let sortnum = this.paginations.page_size * (page - 1);
    //   let table = this.allTableData.filter((item, index) => {
    //     return index >= sortnum;
    //   });
    //   // 设置默认分页数据
    //   this.tableData = table.filter((item, index) => {
    //     return index < this.paginations.page_size;
    //   });
    // },
    // handleSizeChange(page_size) {
    //   // 切换size
    //   this.paginations.page_index = 1;
    //   this.paginations.page_size = page_size;
    //   this.tableData = this.allTableData.filter((item, index) => {
    //     return index < page_size;
    //   });
    // },
    // setPaginations() {
    //   // 总页数
    //   this.paginations.total = this.allTableData.length;
    //   this.paginations.page_index = 1;
    //   this.paginations.page_size = 5;
    //   // 设置默认分页数据
    //   this.tableData = this.allTableData.filter((item, index) => {
    //     return index < this.paginations.page_size;
    //   });
    // },
  },
};
</script>
<style scoped lang="scss">
.vip {
  width: 100%;
  overflow: hidden;
  .vip-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 78px;
    background: #fff;
  }
  .vip-statistics {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 4px;
    padding-top: 78px;
    width: 100%;
    text-align: center;
    .red-num {
      color: #e31e1e;
    }
  }
  .vip-condition {
    padding-top: 18px;
    padding-left: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .vip-all {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 4px;
      display: flex;
      align-items: center;
      .vip-buy {
        width: 48px !important;
        color: #fff;
        height: 34px;
        background: #9a86db;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        font-family: Source Han Sans CN;
        padding-bottom: 12px;
      }
      .el-range-editor.el-input__inner {
        width: 230px;
        padding-left: 10px;
        height: 34px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 0;
      }
    }
    .vip-right {
      .lookDetail {
        width: 100px;
        height: 34px;
        background: #9a86db;
        border-radius: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 12px;
      }
      .inputOp {
        width: 250px;
        .el-input__inner {
          border: 2px solid #cccccc !important;
          border-radius: 17px !important;
        }
      }
      .vip-exprot {
        width: 70px;
        height: 34px;
        border: 2px solid #cccccc;
        border-radius: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        padding-top: 6px !important;
      }
      .vip-yuan {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 4px;
        padding-right: 20px;
      }
    }
  }
  .vip-table {
    padding-top: 18px;
    padding-right: 20px;
  }
}
/deep/ .el-date-editor .el-range__icon {
  padding-left: 25px !important;
}
.vip-watch {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  div {
    text-align: center;
  }
}
/deep/ .el-collapse-item__header {
  border: none;
  background-color: #f4f7fd;
  height: 39px;
}
/deep/ .el-collapse-item__wrap {
  border: none;
  background-color: #f4f7fd;
}
.dialog-title {
  font-size: 22px;
  color: #1a2533;
  text-align: center;
  padding: 20px 10px 0px 10px;
  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.btn-bottm > button {
  width: 80px;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  background: #8d51e4;
  color: #fff;
  border-radius: 0.04rem;
  cursor: pointer;
}
/deep/ .el-radio {
  margin-right: 20px;
}
</style>
